.container {
  max-width: unset !important;
  padding: 0.5rem 1.5rem;
  @media screen and (min-width: 768px) {
    width: 100%;
    // max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 65px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 200px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}